import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "viscon"
    }}>{`Viscon`}</h1>
    <h2 {...{
      "id": "project-outline"
    }}>{`Project outline`}</h2>
    <p>{`Viscon is a company that sells and distributes factory automisation. All data for customers and related projects, machines and parts is managed through RidderIQ (ERP system). HybrIT created a portal for customers in which  they can view their projects, their machines and parts. Customers can also order parts through this portal. A quotation is then sent to ridderIQ.`}</p>
    <h2 {...{
      "id": "architecture"
    }}>{`Architecture`}</h2>
    <p><img alt="Alt text" src={require("./blob/viscon-architecture.png")} /></p>
    <h2 {...{
      "id": "coding--techniques"
    }}>{`Coding & techniques`}</h2>
    <ul>
      <li parentName="ul">{`Using Saleor e-commerce platform`}</li>
      <li parentName="ul">{`Frontend build in React 16.9.9`}</li>
      <li parentName="ul">{`On-premise ridderIQ API build in .NET core (3.1), swagger docs included`}</li>
      <li parentName="ul">{`Docker & helm charts`}</li>
      <li parentName="ul">{`Using GitVersion to auto-version artifacts`}</li>
      <li parentName="ul">{`Azure DevOps pipeline definitions`}</li>
      <li parentName="ul">{`Using git secret to encrypt sensitive information like configuration files`}</li>
      <li parentName="ul">{`Azure SQL (data from ridderIQ gets synced to this)`}</li>
      <li parentName="ul">{`PostgreSQL for saleor`}</li>
      <li parentName="ul">{`GraphQL`}</li>
    </ul>
    <h2 {...{
      "id": "environments"
    }}>{`Environments`}</h2>
    <p>{`There is a semi (O)TP in place. Doing a git pull and running it locally will connect to the test environment. Due to the fact that viscon only has 1 test environment
for ridder and 1 production environment, we don't have a 'staging' env.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://test.viscon.hybrit.io"
        }}>{`https://test.viscon.hybrit.io`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://viscon.hybrit.io"
        }}>{`https://viscon.hybrit.io`}</a></li>
    </ul>
    <p>{`All environments run in one k8s cluster (there is no high demand for resources) seperated by namespaces. The kubernetes cluster runs in Azure and is named `}<inlineCode parentName="p">{`viscon-weu`}</inlineCode>{`. The k8s cluster is seeded with an nginx ingress controller and certificate manager.`}</p>
    <h2 {...{
      "id": "repositories"
    }}>{`Repositories`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Repository`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`front-end`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`viscon front-end repo`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/viscon-saleor-storefront"
            }}>{`https://github.com/HybrIT-dev/viscon-saleor-storefront`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`dashboard`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`viscon dashboard repo`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/viscon-saleor-dashboard"
            }}>{`https://github.com/HybrIT-dev/viscon-saleor-dashboard`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`back-end`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`viscon back-end repo`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/viscon-saleor"
            }}>{`https://github.com/HybrIT-dev/viscon-saleor`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data sync job`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`viscon data import repo`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/viscon-saleor-dataimport-dotnet"
            }}>{`https://github.com/HybrIT-dev/viscon-saleor-dataimport-dotnet`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`image sync job`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Processes images coming from (azure SQL) db and places them on azure storage. Also updates the product / machine through grahpql`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/viscon-saleor-image-processor-dotnet"
            }}>{`https://github.com/HybrIT-dev/viscon-saleor-image-processor-dotnet`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`on premise api`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Api that runs on-premise @ viscon to expose data coming from & into ridderIQ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/viscon-api-erp-dotnet"
            }}>{`https://github.com/HybrIT-dev/viscon-api-erp-dotnet`}</a></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "getting-started-with-development"
    }}>{`Getting started with development`}</h2>
    <p>{`The Saleor storefront follows an MVC type naming convention where the ‘views’ folder holds
the pages/components to be rendered. Saleor also follows React functional components convention using Hooks for built-in shop state
such as for example the shopping Cart. The base components folder( src/components ) is where most of the Saleor standard components are,
and has a standard react style folder structure, where a component exports itself to the rest of the project using an index.ts file.
The Hybrit ( Viscon ) specific components can be found in the base component folder ( src/components/HybritComponents ).
The @next folder ( src/@next ) contains Saleors next generation of components with Atomised folder structure.`}</p>
    <h3 {...{
      "id": "frontend"
    }}>{`Frontend`}</h3>
    <h4 {...{
      "id": "linter"
    }}>{`Linter`}</h4>
    <p>{`Make sure to use the Prettier Formatter extension in your IDE if available.
When pushing your commits the code is strictly checked and rejected If the code does not follow the Prettier format.`}</p>
    <h4 {...{
      "id": "css"
    }}>{`Css`}</h4>
    <p>{`Saleor follows the Scss with BEM style convention for its css.
Because Saleor uses next.js’ serverside rendering and is SEO optimised, the use of inline styling is avoided where possible.
Instead every component or View usually has its own ‘scss’ folder holding an index.scss file which in turn imports the
GlobalStyle variables from src/globalStyles/variables.scss, and holds the scss for this particular component.
Some components however, namely the ones in @next/components explicitly use “styled-components”, and so import ‘styles’ from a styles.tsx instead of an Scss file.`}</p>
    <h4 {...{
      "id": "environment-variables-configuration"
    }}>{`Environment variables configuration`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Create an environment file ( .env ) in the root of the project ( same level as package.json ) and add the Saleor Core API URI for example:
API_URI=`}<a parentName="p" {...{
            "href": "https://test.core.viscon.hybrit.io/graphql/"
          }}>{`https://test.core.viscon.hybrit.io/graphql/`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Make sure .env is added in .gitignore`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Restart the dev server`}</p>
      </li>
    </ul>
    <h4 {...{
      "id": "setting-up-a-basic-new-view"
    }}>{`Setting up a basic new View`}</h4>
    <p>{`In src/views ->`}<br parentName="p"></br>{`
`}{`————————————————————————————————————————————————————————`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Create a folder with your new View name`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Inside the folder create View.tsx ( uppercase first letter )`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Inside the folder create Page.tsx ( uppercase first letter )`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Inside the folder create index.tsx ( lowercase )`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Inside the folder create a folder named 'scss', and inside this folder create a file called 'index.scss'.
Inside this scss file, import the GlobalStyle variables ( src/globalStyles/variables.css ).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If your view needs data from the GraphQL server, then Inside the folder also create queries.tsx ( lowercase )`}</p>
      </li>
    </ul>
    <ul>
      <li parentName="ul">{`View.tsx handles data input/output, queries and state, and it renders the Page.tsx component while passing down any data through its props.`}</li>
      <li parentName="ul">{`Page.tsx renders the components you want to use for this View. Data changes in the Page component are generally passed back up to the View component using callback props, for state management or further queries etcetera.`}</li>
      <li parentName="ul">{`index.tsx creates the default export for View.tsx`}</li>
      <li parentName="ul">{`index.scss serves as the main css for your View`}</li>
      <li parentName="ul">{`queries.tsx is where you construct your GraphQL queries to fetch or manipulate data from the Saleor Core Api.`}</li>
    </ul>
    <p>{`Inside View.tsx ->`}<br parentName="p"></br>{`
`}{`————————————————————————————————————————————————————————`}</p>
    <ul>
      <li parentName="ul">{`create a functional component that returns the imported Page.tsx as a component and optionally passes props with some data/state, as follows:`}</li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`  function SomeView() {
    return <MyImportedPage props={someProps} />
  }
`}</code></pre>
    <p>{`Inside Page.tsx ->`}<br parentName="p"></br>{`
`}{`————————————————————————————————————————————————————————`}</p>
    <ul>
      <li parentName="ul">{`Create a functional component that returns your components here, using the props passed down from View.tsx as follows:`}</li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`  function SomePage(props) {
    return <MyImportedComponent props={props} />
  }
`}</code></pre>
    <p>{`Inside queries.tsx ( this section only applies if your View needs to communicate with the Saleor Core ) ->`}<br parentName="p"></br>{`
`}{`————————————————————————————————————————————————————————`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Add following import at top of file:
`}<inlineCode parentName="p">{`import { TypedQuery } from "../../core/queries";`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Create a const which holds your new query as follows:
`}<inlineCode parentName="p">{`export const someNameDataQuery = some-query-goes-here;`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In your terminal run the codegen to automatically generate the typescript types for your new query as follows:
$ npm run codegen
(Note: the types will appear in a newly created folder next to your queries.tsx, called ‘gqlTypes’ and the generated types file(s) will automatically have the same name that you give your
query inside gql some-query-goes-here)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Import the new type file(s) into queries.tsx as follows:
`}<inlineCode parentName="p">{`import { MyTypesFile } from "./gqlTypes/MyTypesFile”;`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Create an exported const which holds your new query as a TypedQuery component using your generated type(s) as its argument as follows:
export const TypedSomeNameDataQuery = `}<inlineCode parentName="p">{`TypedQuery<MyImportedTypesFile>(someNameDataQuery);`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If your query uses query variables you can import those variable types from your generated types file and add them as follows:
export const TypedSomeNameDataQuery = `}<inlineCode parentName="p">{`TypedQuery<MyImportedTypesFile, MyImportedTypesFileVariables>(someNameDataQuery);`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Inside View.tsx import your newly created TypedQuery from queries.tsx and return it from your main function. Inside the TypedQuery, return your Page component
and pass the TypedQuery callback as a prop down with the returned Page component as follows:`}</p>
      </li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`const queryVariables = {}
function MyView() {
    return <TypedSomeNameDataQuery variables={queryVariables}>
        {callbackFromQuery => {
            return <MyPageComponent props={callbackFromQuery}>
        }
           </TypedMyNameDataQuery>
}
`}</code></pre>
    <ul>
      <li parentName="ul">{`sidenote:
You can use the generated types in any component throughout the project.
Generated type variables ( $ npm run codegen ) are exported by default and so can conveniently be imported or copied into different components and pages throughout the project to be used as type definitions where applicable.`}</li>
    </ul>
    <h4 {...{
      "id": "setup-a-new-route-for-your-view"
    }}>{`Setup a new Route for your View`}</h4>
    <p>{`Inside app/routes/paths.ts ->`}<br parentName="p"></br>{`
`}{`————————————————————————————————————————————————————————`}</p>
    <ul>
      <li parentName="ul">{`Add an exported path const which holds your endpoint string and pass it as the value in the path prop in
your new Route in AppRoutes.tsx (see below)`}</li>
    </ul>
    <p>{`Inside AppRoutes.tsx ->`}<br parentName="p"></br>{`
`}{`————————————————————————————————————————————————————————`}</p>
    <ul>
      <li parentName="ul">{`import your new View.tsx in src/app/routes/approutes`}</li>
      <li parentName="ul">{`Create a new route in the switch section and pass your imported View.tsx in its component prop.`}</li>
      <li parentName="ul">{`Pass your newly created path into the path prop.`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`You can now view your page using your newly created endpoint
(e.g.: `}<a parentName="li" {...{
          "href": "http://localhost:3000/myPage"
        }}>{`http://localhost:3000/myPage`}</a>{`)`}</li>
    </ul>
    <h4 {...{
      "id": "globalstyles"
    }}>{`GlobalStyles`}</h4>
    <ul>
      <li parentName="ul">{`src/globalStyles holds the global style constants for the entire app. These are imported in most of the scss files throughout.`}</li>
      <li parentName="ul">{`src/@next/globalStyles holds the global style constants for the @next components. These components mainly include
dynamic parts of the standard Saleor storefront such as Grid cards and List items, and use “styled-components” instead of scss.
(Note: Hybrit list item components though, are in src/components/HybrITComponents)`}</li>
    </ul>
    <h4 {...{
      "id": "graphql-playground-to-test-and-create-graphql-queries-before-adding-them"
    }}>{`GraphQL Playground to test and create GraphQL queries before adding them`}</h4>
    <p><a parentName="p" {...{
        "href": "https://test.core.viscon.hybrit.io/graphql/"
      }}>{`https://test.core.viscon.hybrit.io/graphql/`}</a></p>
    <h4 {...{
      "id": "hooks"
    }}>{`Hooks`}</h4>
    <ul>
      <li parentName="ul">{`The Sailor SDK provides several hooks for tracking shop and user state, check its type definitions or console output to see all hooks.`}</li>
      <li parentName="ul">{`Some Viscon components use the useCart hook for tracking shopping cart state.`}</li>
      <li parentName="ul">{`Some Viscon components use the queryParams Hook to pass state from one component to another.`}</li>
      <li parentName="ul">{`Authentication is automatically handled by Saleor’s modules and the backend.`}</li>
      <li parentName="ul">{`The src/hooks directory is where custom hooks can be created, it also holds a hook for clickaway functionality`}</li>
    </ul>
    <h4 {...{
      "id": "rest--apiwrapper"
    }}>{`REST / apiWrapper`}</h4>
    <ul>
      <li parentName="ul">{`Some components fetch their state from, or, mutate data using a Viscon specific REST Api on a server outside of the Saleor Framework. This Api is called using an Api wrapper which is located in the root of the project and has the filename: apiWrapper.tsx`}</li>
    </ul>
    <h4 {...{
      "id": "translation"
    }}>{`Translation`}</h4>
    <ul>
      <li parentName="ul">{`Saleor aims to have automatic translation based on location by using the react-intl package, however many of the translation data/functionality is still not fully developed by Mirumee ( creator of Saleor ). This includes the lack of any option to manually change the language when using the storefront.`}</li>
      <li parentName="ul">{`When writing any plain text such as titles and attribute names/descriptions etcetera, it is recommended to use the <FormattedMessage defaultMessage=“my message” />
component, the translations can then be added to the intl.ts ( src/intl.ts ) if they are not handled automatically.`}</li>
    </ul>
    <h4 {...{
      "id": "dashboard-configurable-links-and-components"
    }}>{`Dashboard configurable Links and Components`}</h4>
    <ul>
      <li parentName="ul">{`In some components such as for example ‘MainMenu’, the links are by default configured and provided by the Saleor Core backend. They are fetched by a typed query on page load, and In order to change those you need to configure them using the Saleor Dashboard or, replace them with your custom solution.`}</li>
    </ul>
    <h2 {...{
      "id": "developer-notes"
    }}>{`Developer notes`}</h2>
    <h3 {...{
      "id": "vpn"
    }}>{`VPN`}</h3>
    <p>{`To access the viscon network and be able to either deploy the api or query the (staging) database, you will need a VPN client. The client used by viscon, is `}<a parentName="p" {...{
        "href": "https://www.fortinet.com/support/product-downloads#vpn"
      }}>{`Forticlient`}</a>{`. The password can be found in LastPass. You can use the following VPN settings:`}</p>
    <p><img alt="Alt text" src={require("./blob/viscon-vpn-settings.png")} /></p>
    <p>{`User: Markdi`}<br parentName="p"></br>{`
`}{`Server: access.viscongroup.eu, port 443`}</p>
    <h3 {...{
      "id": "importing-data"
    }}>{`Importing data`}</h3>
    <p>{`The data is imported through a plugin, which is called on a regular basis through a webhook. You can find the plugin at saleor\\plugins\\helloworld. There you will find the sql statements and tasks which are executed when importing. RidderIQ exports data to a SQL database. The plugin connects to this SQL database and starts importing data into saleor's own postgres database.`}</p>
    <h3 {...{
      "id": "user-filtering"
    }}>{`User filtering`}</h3>
    <p>{`The filtering of the projects/machines for a user happens in saleor\\core\\models.py, in the method visible_to_viscon_user. This will make sure that the visible projects and machines are part of the user, and not someone else.`}</p>
    <h3 {...{
      "id": "adjusting-the-product-model"
    }}>{`Adjusting the product model`}</h3>
    <p>{`To adjust the database model, go to saleor\\product\\models.py. Here you can adjust fields for the database (not the graphql part). When you are done, make sure to create the migration. To do this, you have to:
1. set an environment variable DATABASE_URL (you can copy/paste the one from values.test.yaml).
2. Execute the command: `}<inlineCode parentName="p">{`python manage.py makemigrations`}</inlineCode>{`
3. Commit & push the code (make sure the migration file is added ofcourse)
4. Once the application is deployed, open a terminal to the kubernetes pod and execute `}<inlineCode parentName="p">{`python manage.py migrate`}</inlineCode>{` (this does NOT happen automatically)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      